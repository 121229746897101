.left-auto {
    margin-left: auto;
}

.edit-course-wrapper {
    // padding: 65px 0;
    padding-bottom: 65px;
    background-color: #f0f4f7;
    min-height: 100vh !important;

    @media (min-width: 576px) {
        .container {
            max-width: 95%;
        }
    }

    .lesson-icon {
        border-radius: 6px !important;
        box-shadow: var(--box-shadow);
        overflow: hidden;
    }

    .course-image {
        img {
            border-radius: 6px;
            object-fit: cover;
        }
    }

    .edit-course-card {
        // border: 1px solid #dde3ea;
        border-radius: 0.5rem;
        margin-bottom: 15px;
        box-shadow: 0px 1px 3px 0px rgba(126, 141, 160, 0.15);
        background-color: var(--color-white) !important;
        overflow: hidden;
    }

    .edit-course-card-body {
        border-bottom: 1px solid var(--border-color);
        padding: 1.5rem 3rem;

        .edit-course-links {

            //   margin-right: 30px;
            a {
                color: var(--text-link);
                font-size: 13px;
                font-weight: 500;
                text-decoration: none;
                position: relative;
                &::before {
                    content: "\2022";
                    color: var(--text-link);
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: 6px;
                }
            }
        }

        .edit-course-title {
            color: var(--text-primary);
            font-size: 1.25rem;
            font-weight: 500;
            margin: 0;
            margin-bottom: 0.6rem;
        }

        p {
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
            color: var(--text-color);
            margin: 0;
            margin-bottom: 1rem;
        }

        .mt-1 {
            margin-top: 1.2rem;
        }

        .mb-1 {
            margin-bottom: 1.2rem;
        }

        .mb-3 {
            margin-bottom: 3rem;

            @media screen and (max-width: 600px) {
                margin-bottom: 1rem;
            }
        }

        .edit-course-card-content {
            padding: 0 1.2rem;
        }
    }

    @media screen and (max-width: 600px) {
        .edit-course-card-body {

            // padding: 1rem !important;
            .edit-course-card-content {
                padding: 1rem 0 !important;
            }
        }
    }
}